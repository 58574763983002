<template>
  <div class="addOrEdit">
    <h1>藏品详情</h1>
    <el-form ref="ruleForm" :model="form" label-width="120px">
      <el-form-item label="藏品名称" required prop="name">
        <el-input disabled v-model="form.name" :maxlength="10" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="藏品简介" required prop="desc">
        <el-input disabled v-model="form.desc" :maxlength="10" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="藏品描述" required prop="content">
        <el-input disabled v-model="form.content" :maxlength="116" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="藏品图片" required prop="imageUrl">
        <el-upload
          disabled
          class="avatar-uploader"
          action=""
          :show-file-list="false">
          <img v-if="form.imageUrl" :src="$host + form.imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="藏品音频描述" required prop="fileList">
        <el-upload
          class="upload-demo"
          action=""
          disabled
          :on-preview="handlePreview"
          :file-list="form.fileList">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传.mp3文件，且不超过100MB</div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <div class="btns">
          <el-button type="primary" @click="back">返回</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: '',
      form: {
        name: '',
        desc: '',
        content: '',
        imageUrl: '',
        fileList: []
      }
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getInfo()
    }
  },
  methods: {
    back() {
      window.history.go(-1)
    },
    getInfo() {
      this.$fetch({
        url: '/getCollectionInfo',
        method: 'post',
        data: {
          id: this.id
        }
      }).then((res) => {
        if (res.code == 200) {
          this.form.name = res.data.title
          this.form.desc = res.data.description
          this.form.content = res.data.content
          this.form.imageUrl = res.data.imgUrl
          this.form.fileList = [{
            name: res.data.audioUrl.split('/')[res.data.audioUrl.split('/').length - 1],
            url: this.$host + res.data.audioUrl
          }]
        } else {
          this.$message.error(res.msg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      window.open(file.url)
    }
  }
}
</script>

<style lang="less" scoped>
  .addOrEdit {
    padding: 30px;

    h1 {
      text-align: center;
      padding-bottom: 10px;
    }
    .btns {
      text-align: center;
    }
    ::v-deep .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    ::v-deep .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    ::v-deep .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    ::v-deep .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    ::v-deep .el-input.is-disabled .el-input__inner {
      color: black;
    }
  }
</style>